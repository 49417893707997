import React from 'react';
import {
    Ad,
    AppContent,
    baseUnit,
    ErrorBoundary,
    HalfScreenWrapper,
    MEDIA_BREAKPOINTS,
    OneOTCThreeHCVertical,
    OneOTCThreeHLC,
    OneTwoOTCHorizontal,
    ScrollableArticleList,
    SecondarySectionTitle,
    Section,
    SectionDividerWithIcon,
    SectionHeader,
    SectionWithTitle,
    ThreeHCOneOTC,
    TitleDotsBackgroundImage,
    TrianglesIconForDivider,
    FiveHLCNumberedList,
    SectionHeaderWithBackground,
} from 'mm-ui-components';
import { Store } from 'redux';
import { useSelector } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { Page } from '../Page';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import {
    getMetadataDescription,
    getMetadataImage,
    getMetadataTitle,
    getSectionArticlesByKey,
    getMoreButtonSvgByKey,
    getMoreButtonTextByKey,
    getSectionTitleByKey,
    getHreflangData,
} from '../../store/template/homePage/homePageManila/homePageManila.selectors';

import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import { getAdSlotId, getAdSlotOnSuccess } from '../../store/config/config.selectors';

interface HomePageProps {
    store: Store<AppState, AppActions>;
    errorProvider: ErrorReporter;
}

declare global {
  interface Window {
    mmFactsWidget_onInit: { push: (x: string) => void };
  }
}

const getArticlesInTemplate = (state: AppState) => {
    const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
    const contentSection1Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1)(state) || [];
    const contentSection2Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2)(state) || [];
    const contentSection3Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3)(state) || [];
    const contentSection4Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_4)(state) || [];
    const contentSection5Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_5)(state) || [];

    return [
        ...topSectionArticles,
        ...contentSection1Articles,
        ...contentSection2Articles,
        ...contentSection3Articles,
        ...contentSection4Articles,
        ...contentSection5Articles,
    ];
};

export const HomePageManilaComponent: React.FunctionComponent<HomePageProps> = ({ store, errorProvider }) => {
    useMeasureHydrateTime();
    return (
      <Page store={store} errorProvider={errorProvider}>
        <ErrorBoundary>
          <HomePageManilaContent />
        </ErrorBoundary>
      </Page>
    );
};

const createStyle = () => StyleSheet.create({
    sectionsWrapper: {
        [MEDIA_BREAKPOINTS.large]: {
            gridRowGap: `${baseUnit * 1.5}px`,
        },
        [MEDIA_BREAKPOINTS.medium]: {
            gridRowGap: `${baseUnit * 1.5}px`,
            padding: '0 20px 0 20px',
        },
        [MEDIA_BREAKPOINTS.small]: {
            gridRowGap: `${baseUnit}px`,
            padding: '0 10px 0 10px',
        },
        display: 'grid',
        gridTemplateColumns: '1fr',
        marginTop: `${baseUnit * 0.25}px`,
        marginBottom: `${baseUnit * 0.5}px`,
        justifyItems: 'center',
    },
    dividerWrapper: {
        paddingBottom: '10px',
    },
});

const FactsWidget = () => {
  React.useEffect(() => {
    window.mmFactsWidget_onInit = window.mmFactsWidget_onInit || [];
    window.mmFactsWidget_onInit.push('init-widget');
  }, []);
  return <div id="mm-facts-widget" />;
};

export const HomePageManilaContent: React.FunctionComponent = () => {
    const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)) || [];
    const contentSection1Title = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_1));
    const contentSection1Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1));
    const contentSection2Title = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_2));
    const contentSection2Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2));
    const contentSection2MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_2));
    const contentSection2MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_2));
    const contentSection3Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3));
    const contentSection3Title = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_3));
    const contentSection3MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_3));
    const contentSection3MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_3));
    const contentSection4Title = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_4));
    const contentSection4Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_4));
    const contentSection5Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_5));
    const contentSection5Title = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_5));
    const contentSection5MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_5));
    const contentSection5MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_5));
    const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
    const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
    const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
    const oneOnOneOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
    const metaTitle = useSelector(getMetadataTitle);
    const metaDescription = useSelector(getMetadataDescription);
    const pageImage = useSelector(getMetadataImage);
    const articles = useSelector(getArticlesInTemplate);
    const hreflangData = useSelector(getHreflangData) || [];
    const style = createStyle();

    return (
      <React.Fragment>
        <MetaTags articles={articles} title={metaTitle} description={metaDescription} pageImage={pageImage} alternateUrls={hreflangData} />
        <HeadScripts />
        <Theme>
          <AppContent>
            <NavigationLayout mainWrapperConfigThemeKey="mainContent">
              <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
              <div className={css(style.sectionsWrapper)}>
                <Section>
                  <OneOTCThreeHLC cards={topSectionArticles.slice(0, 4)} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                </Section>
                <Section>
                  <ScrollableArticleList cardsProps={topSectionArticles.slice(4)} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                </Section>

                <Section>
                  <div className={css(style.dividerWrapper)}>
                    <SectionDividerWithIcon iconComponent={TrianglesIconForDivider} />
                  </div>
                  <FactsWidget />
                </Section>

                <HalfScreenWrapper paddingFactors={{ large: 0, medium: 0, small: 0 }}>
                  <SectionWithTitle>

                    <SectionHeaderWithBackground
                      paddingFactors={{ large: 0, medium: 0, small: 0 }}
                      backgroundComponent={TitleDotsBackgroundImage}
                    >
                      <SecondarySectionTitle>
                        {contentSection1Title}
                      </SecondarySectionTitle>
                    </SectionHeaderWithBackground>
                    <FiveHLCNumberedList cards={contentSection1Articles} />
                  </SectionWithTitle>

                  <SectionWithTitle>
                    <div className={css(style.dividerWrapper)}>
                      <SectionDividerWithIcon />
                    </div>
                    <SectionHeader
                      moreButtonSvg={contentSection2MoreButtonSvg}
                      moreButtonText={contentSection2MoreButtonText}
                      paddingFactors={{ large: 0, medium: 0, small: 0 }}
                    >
                      <SecondarySectionTitle>
                        {contentSection2Title}
                      </SecondarySectionTitle>
                    </SectionHeader>
                    <OneOTCThreeHCVertical cards={contentSection2Articles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                  </SectionWithTitle>
                </HalfScreenWrapper>

                <SectionWithTitle>
                  <div>
                    <SectionDividerWithIcon iconComponent={TrianglesIconForDivider} />
                  </div>
                  <SectionHeader
                    moreButtonSvg={contentSection3MoreButtonSvg}
                    moreButtonText={contentSection3MoreButtonText}
                    paddingFactors={{ large: 0, medium: 0, small: 0 }}
                  >
                    <SecondarySectionTitle>
                      {contentSection3Title}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <ThreeHCOneOTC cards={contentSection3Articles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                </SectionWithTitle>

                <SectionWithTitle>
                  <div>
                    <SectionDividerWithIcon iconComponent={TrianglesIconForDivider} />
                  </div>
                  <SectionHeader
                    moreButtonSvg={null}
                    moreButtonText={null}
                    paddingFactors={{ large: 0, medium: 0, small: 0 }}
                  >
                    <SecondarySectionTitle>
                      {contentSection4Title}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <OneTwoOTCHorizontal cards={contentSection4Articles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                </SectionWithTitle>

                <SectionWithTitle>
                  <div>
                    <SectionDividerWithIcon iconComponent={TrianglesIconForDivider} />
                  </div>
                  <SectionHeader
                    moreButtonSvg={contentSection5MoreButtonSvg}
                    moreButtonText={contentSection5MoreButtonText}
                    paddingFactors={{ large: 0, medium: 0, small: 0 }}
                  >
                    <SecondarySectionTitle>
                      {contentSection5Title}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <ThreeHCOneOTC cards={contentSection5Articles} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
                </SectionWithTitle>
              </div>
              <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />
            </NavigationLayout>
          </AppContent>
        </Theme>
      </React.Fragment>
    );
};
